import { Injectable } from '@angular/core';
import { SessionUser, UserClientVm, DeleteUserByFirm, ResetPasswordData } from '../../store/user/user.interface';
import { HttpBaseService } from './http-base.service';
import { Store } from '@ngrx/store';
import { getSessionUser } from '../../store/user/user.selector';

@Injectable({
  providedIn: 'root'
})

export class UserService {
  
  userObject!: UserClientVm;

  constructor(private http:HttpBaseService, private store: Store<SessionUser>) { 
    this.store.select(getSessionUser).subscribe((user:UserClientVm)=>{
      this.userObject = user;
    });
  }

  getSessionUser() {
    return this.userObject; 
  }

  approveUser(reviewerId: string) {
    const url = `users/${reviewerId}/approve`
    return this.http.put(url);
  }

  deleteUserByFirm(reviewerId: string, data: DeleteUserByFirm) {
    const url = `users/deleteReviewerByFirm/${reviewerId}`
    return this.http.delete(url, data);
  }

  getUserId(){
    return this.userObject._id;
  }

  getUserRole(){
    return this.userObject.role;
  }
  
  getPositions(){
    return this.http.get('positions');
  }

  getUserFirmId(){
    return this.userObject.firm._id;
  }

  resetPassword (data:ResetPasswordData) {
    return this.http.put('auth/resetPassword', data);
  };

  verifyPhone(body: any ) {
    return this.http.put('users/verifyPhone', body);
  }

  getAbdetails(reviewerId: string) {
    return this.http.get(`users/${reviewerId}/abDetails`);
  }

  invokeBackgroundCheck(reviewerId:string, body:any) {
    return this.http.post(`users/${reviewerId}/manualCheck`, body);
  };

  invokeEducationBackgroundCheck(reviewerId:string, body:any) {
    return this.http.post(`users/${reviewerId}/approveEducation`, body);
  };

}
